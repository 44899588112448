<template>
	<div class="h-100 d-flex align-items-center pt-0" style="padding-top: 30px !important;">
		<!-- <img src="../assets/nf-1.png" alt="" /> -->
		<div class="container">
			<carousel v-bind:autoplay="true" v-bind:loop="true" :autoplayTimeout="10000" :perPage="1" :easing="'ease'"
				:paginationColor="'#FCEBEA'" :paginationActiveColor="'#EA1D2C'" :paginationSize="8" :paginationPadding="4">
				<slide class="text-center">
					<!--<h1 class="text-red text-center">Você vai precisar</h1>-->
					<p class="text-center">Você vai precisar: Do número do seu cupom ou da sua nota fiscal</p>
					<img src="../assets/01-nota.png" style="max-width: 80%; max-height: 300px" class="mb-3" />
					<div class="d-flex">
						<img src="../assets/svg/check.svg" alt="" class="align-self-center mr-3" />
						<p class="mb-0 text-left">Confira se todos os campos podem ser lidos e nenhuma parte está cortada</p>
					</div>
				</slide>
				<slide class="text-center">
					<!--<h1 class="text-red text-center">Você vai precisar</h1>-->
					<p class="text-center">Você vai precisar: Do CNPJ do vendedor</p>
					<img src="../assets/03-cnpj.png" style="max-width: 80%; max-height: 300px" class="mb-3" />
					<div class="d-flex">
						<img src="../assets/svg/check.svg" alt="" class="align-self-center mr-3" />
						<p class="mb-0 text-left">Confira se todos os campos podem ser lidos e nenhuma parte está cortada</p>
					</div>
				</slide>
				<slide class="text-center">
					<!--<h1 class="text-red text-center">Você vai precisar</h1>-->
					<p class="text-center">Você vai precisar: Da data da sua compra</p>
					<img src="../assets/02-data.png" style="max-width: 80%; max-height: 300px" class="mb-3" />
					<div class="d-flex">
						<img src="../assets/svg/check.svg" alt="" class="align-self-center mr-3" />
						<p class="mb-0 text-left">Confira se todos os campos podem ser lidos e nenhuma parte está cortada</p>
					</div>
				</slide>
			</carousel>
		</div>
		<ifood-footer class="p-3">
			<router-link :to="{ name: 'NewRefundForm' }" class="btn btn-primary btn-lg btn-block">Continuar</router-link>
		</ifood-footer>
	</div>
</template>

<script>
import Product from "../components/Product.vue";
import { mapGetters, mapActions } from 'vuex';
import ifoodFooter from "@/components/Footer.vue";
import { required, minLength } from 'vuelidate/lib/validators'

import CurrencyInput from "../components/CurrencyInput.vue";

const FormData = require('form-data');

export default {
	name: 'Rules',
	directives: {
  	},
	components: {
		Product,
		CurrencyInput,
		ifoodFooter
	},
	data() {
		return {
		}
	},
	methods: {
		...mapActions({

		}),
	},
	validations: {
	},
	mounted(){
	}
}
</script>

<style lang="scss" scoped>

h1 {
	font-size: 1.5rem;
	margin-bottom: 20px;
}

.box-scroll {
	background-color: $white;
	padding: 1.3rem 1.55rem 1.3rem 1.3rem !important;
	border-radius: 10px;
	font-family: "SulSans";
	p {
		font-size: 1.125rem;
		font-weight: 100;
	}
}
#preview {
	text-align: center;
    max-height: 280px;
    overflow: scroll;
    margin-bottom: 15px;
}

</style>